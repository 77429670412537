import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Dayjs } from 'dayjs';

import { notification, DatePicker, Table, Select, Input, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { ReloadOutlined, FilterFilled, UnorderedListOutlined, CheckOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import Bill from '@src/core/controls/bill/bill';

import { exception, info } from '@extensions/notification';
import { getEnumList, delayAction } from '@extensions/utils';
import { userLoaded } from '@store/actions';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IOrderFilter } from '@entities/order-filter';
import { IOrder } from '@entities/order';
import { IUserSession } from '@entities/user-session';
import { IBill } from '@entities/bill';
import { IBillFilter } from '@entities/bill-filter';

import { TruckStatus, enumLabel as truckStatusLabel } from '@enums/truck-status';

import { BillIcon } from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Orders = () => {
    const { RangePicker } = DatePicker;

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [api, notificationContextHolder] = notification.useNotification();

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const d = useAppDispatch();

    const [showFilter, setShowFilter] = useState(true);
    const [filter, setFilter] = useState<IOrderFilter>({ statuses: [TruckStatus.New, TruckStatus.OnWay] });
    const [orders, setOrders] = useState<Array<IOrder>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);

    const [currentItem, setCurrentItem] = useState<IOrder>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [statuses] = useState(getEnumList(TruckStatus, truckStatusLabel));

    const [bills, setBills] = useState<Array<IBill>>([]);
    const [showBillsRequired, setShowBillsRequired] = useState<boolean>(false);
    const [openBill, setOpenBill] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setOrders([]);
        setRefreshRequired(false);
        setLoading(true);

        serverFetch(userSession.isDemo ? 'demo' : 'orders', { method: 'GET', queryParams: filter })
            .then((data) => {
                setOrders(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения заказов', ex, () => d(userLoaded(undefined)));
            });
    }, [refreshRequired]);

    useEffect(() => {
        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (!showBillsRequired) return;

        setShowBillsRequired(false);

        if (bills.length <= 0) {
            info(api, 'Выставленных счетов не найдено');
            return;
        }

        setOpenBill(true);
    }, [showBillsRequired, bills]);

    const onLoadBill = () => {
        let tariffFilter: IBillFilter = {
            userId: userSession.userId,
            boxGroupId: currentItem?.boxGroupId,
            completedOnly: true,
            isArchived: false,
        };

        serverFetch('bills', { method: 'GET', queryParams: tariffFilter })
            .then((data) => {
                setShowBillsRequired(true);
                setBills(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: `Детали`,
                        key: 'details',
                        disabled: !currentItem,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentItem?.boxGroupId}`);
                        },
                    },
                    {
                        label: `Счет`,
                        key: 'bills',
                        disabled: !currentItem,
                        icon: <BillIcon />,
                        onClick: () => onLoadBill(),
                        style: { display: userSession.userSettings?.showBills ? '' : 'none' },
                    },
                ]}
                farCommands={
                    userSession.isDemo
                        ? []
                        : [
                              {
                                  label: 'Фильтр',
                                  key: 'filter',
                                  type: showFilter ? 'primary' : '',
                                  icon: <FilterFilled />,
                                  onClick: () => {
                                      setShowFilter(!showFilter);
                                  },
                              },
                          ]
                }
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Input
                        style={{ width: 100 }}
                        key='consigneeCode'
                        placeholder='ID'
                        value={filter.consigneeCode}
                        onChange={(data) => {
                            setFilter({ ...filter, consigneeCode: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 120 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter.markingCode}
                        onChange={(data) => {
                            setFilter({ ...filter, markingCode: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter.awbNumber}
                        onChange={(data) => {
                            setFilter({ ...filter, awbNumber: data.target.value });
                        }}
                    />,
                    <Select
                        key='status'
                        placeholder='Статус машины'
                        value={filter.statuses}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => setFilter({ ...filter, statuses: value })}
                        options={statuses.map((s) => {
                            return { value: s.value, label: s.label };
                        })}
                    />,
                    <RangePicker
                        style={{ width: 220 }}
                        allowEmpty={[true, true]}
                        key='loading'
                        format='DD.MM.YYYY'
                        placeholder={['Отгрузка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={[filter.loadingFrom && dayjs(filter.loadingFrom), filter.loadingFrom && dayjs(filter.loadingTo)]}
                        onChange={(value) => {
                            if (!value) {
                                setFilter({
                                    ...filter,
                                    loadingFrom: undefined,
                                    loadingTo: undefined,
                                });

                                return;
                            }

                            if (value[0]) {
                                setFilter({
                                    ...filter,
                                    loadingFrom: dayjs(value[0]).set('hour', 0).set('minute', 0).set('second', 0),
                                });
                            }

                            if (value[1]) {
                                setFilter({
                                    ...filter,
                                    loadingTo: dayjs(value[1]).set('hour', 23).set('minute', 59).set('second', 59),
                                });
                            }
                        }}
                    />,
                ]}
                onClear={() => setFilter({ statuses: [TruckStatus.New, TruckStatus.OnWay] })}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length === 1) {
            let item = orders.find((o) => o.boxGroupId === selectedRowKeys[0]);
            setCurrentItem(item);
        } else {
            setCurrentItem(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IOrder> = [
            {
                title: 'ID',
                align: 'center',
                dataIndex: 'consigneeCode',
                width: 60,
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 180,
            },
            {
                title: 'Страна',
                width: 150,
                dataIndex: 'countryName',
            },
            {
                title: 'AWB',
                align: 'center',
                width: 250,
                render: (_, record) => {
                    return (
                        record.awbNumber && (
                            <Tag color='#efefef' style={{ marginLeft: 10, color: '#000000' }}>
                                {record.awbNumber}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'boxQty',
                onCell: (record) => ({
                    style: {
                        background: 'var(--primary-color)',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: 'Догруз',
                width: 70,
                align: 'center',
                render: (_: any, record: IOrder) => {
                    return record.isExtraConsignment && <CheckOutlined />;
                },
            },
            {
                title: 'Отгрузка',
                width: 120,
                align: 'center',
                render: (_: any, record: IOrder) => {
                    return record.loadingOn && dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY');
                },
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: 'Статус',
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record: IOrder) => {
                    if (record.truckStatus === TruckStatus.New) {
                        return (
                            <span style={{ background: 'var(--primary-color)', padding: '1px 4px 2px', marginLeft: 10 }}>
                                Формирование заказа
                            </span>
                        );
                    }
                    return (
                        <>
                            {record.waypointName && (
                                <span style={{ background: 'var(--primary-color)', padding: '1px 4px 2px', marginLeft: 10 }}>
                                    {record.waypointName}
                                </span>
                            )}
                        </>
                    );
                },
            },
        ];

        return (
            <Table
                rowKey='boxGroupId'
                size='small'
                columns={columns}
                dataSource={orders}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.boxGroupId || '']);
                        },
                    };
                }}
                scroll={{ y: `calc(100vh - ${showFilter ? '240px' : '175px'})` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {!userSession.isDemo && showFilter && renderFilter()}
            {renderTable()}

            {openBill && (
                <Bill
                    bills={bills}
                    onClose={() => {
                        setBills([]);
                        setOpenBill(false);
                    }}
                />
            )}

            {notificationContextHolder}
        </>
    );
};

export default Orders;
